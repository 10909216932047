$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
@import "../../../styles/params";
:global(.viptooltip){
  width: 240px;
  background: #303030!important;
  margin-right: 0px!important;
  margin-top: 8px!important;
  line-height: 1.3!important;
  padding-left: 16px!important;
  height: 60px;
}
.activityLogo{
  width: 80px; height: 52px;
  background-repeat: no-repeat;
  background-position: 0px 50%;
  backface-visibility: hidden;
  background-size: 90px 60px;
  background-image: url('#{$cdn_dz_path}/bbw/vip_logo.png');
  position: absolute; left:4px; top:15px;
}
.userTypeBox:global(.dropdownWrapper){
  /*display: block;
  z-index: 9999;*/
  height: 398px;
  border-radius: 6px;
  width: 380px;
  margin-left: -218px;
  margin-top:4px;
  text-align: center;

  &::before{
    content: " ";
    border: 8px solid transparent;
    border-bottom: 8px solid #e2e2e2;
    width: 0;
    height: 0;
    position: absolute;
    top: -7px;
    left: 50%;
    filter:drop-shadow(0px -1px 0px  rgba(0, 0, 0, 0.1));
    border-radius: 20%;
    margin-left: 21px;
  }
  .more {
    a {
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      color: #111111 !important;
      line-height: 17px;
      display: block;
      position: relative;
      left: 5px;
      letter-spacing: 0;
    }
  }
}
.dropdownMain:global(.dropdownMain){
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0 0 12px 0;
  border-radius: 8px;
}
.top-content {
  background-image: url("#{$cdn_dz_path}/p/top-content.png");
  background-repeat: no-repeat;
  background-color: #e2e2e2 ;
  width: 100%;
  height: 145px;
  padding: 13px 18px;
  background-size: cover;
  button {
    position: relative;
    border: none;
    width: 332px;
    height: 42px;
    background: #006EFE;
    box-shadow: 0px 2px 4px 0px #00214D;
    border-radius: 4px;
    &:hover{
      background-color: #2065dc;
      border-color:#2065dc;
      &:before{
        content: '';
        position: absolute;
        top: 0;left: 0;right:0;bottom:0;
        background-color: rgba(0,0,0,0.1);
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 16px;
      position: relative;
      letter-spacing: 1px;
      a {
        background: url("../../../assets/images/freetime.png");
        font-size: 12px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1) !important;
        line-height: 20px;
        position: absolute;
        background-size: 100%;
        width: 112px;
        height: 32px;
        top: -20px;
        z-index: 1111;
      }
    }
  }
}
.usetype {
  text-align:center;
  >p {
    font-size: 22px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 30px;
    letter-spacing: 0px;
    span {
      color: #FF4D4D;
    }
  }
  .beInviteInfo{
    padding-top: 8px;
    p:first-child{
      margin-bottom: 4px;
    }
    p:last-child{
      margin-bottom: 6px;
    }
    >p{
      color: #333333;
      font-size: 16px;
      line-height: 16px;
      .keyWord{
        color: #FF4D4D;
      }
    }
  }
  .smallTip_pesonal{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    color: #AAAAAA;
    margin: 2px 0;
    letter-spacing: 0;
    .team_tips{
      position: relative;
      .vip_help_box{
        position: absolute;
        right: -16px;
        top: 2px;
        width: 12px;
        height: 12px;
        .help_icon{
          width: 12px;
          height: 12px;
          background-size: cover;
          background-image: url("#{$cdn_dz_path}/td/vip_help.png");
          background-repeat: no-repeat;
          &:hover{
            background-image: url("#{$cdn_dz_path}/td/vip_help_hover.svg");
          }
        }
      }

    }
    .vip_help{
      //width:240px;
      //right: 0px;
      //top: 2px;
      .downVipTip{
        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }

  .smallTip {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    margin: 12px 0 18px;
    .blueFont{
      color: #006EFE;
    }
    &.smallTip_team{
      margin: 8px 0 0px;
    }
  }
}
.bottom-content {
  .title {
    margin-top: 14px;
    p {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0px;
      line-height: 22px;
    }
    .smalltitle {
      margin-top: 6px;
      font-size: 10px;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
    }
  }
  .wrapList {
    margin-top: 12px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-wrap: wrap;
    padding: 0 17px;
    .item {
      width: 76px;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 13px;
      position: relative;
      div {
        display: -webkit-flex;
        width: 38px;
        height: 37px;
        //border: 1px solid rgba(255, 77, 77, 1);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .bg {
          background-size: 100%;
        }
      }
      p {
        margin-top: 6px;
        font-size: 12px;
        //font-weight: 600;
        color: #333333;
        line-height: 12px;
        white-space: nowrap;
        letter-spacing: 0px;
      }
      .constr {
        display: inline-block;
        transform: scale(0.5);
        margin-top: 5px;
        font-size: 16px;
        font-weight: 400;
        color: #767676;
        line-height: 10px !important;
        white-space: nowrap;
      }
      .small_text{
        width: 68px;
        height: 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 2px 0px rgba(255, 77, 77, 0.2);
        border-radius: 2px;
        margin-top: 0;
        transform: scale(0.5);
        font-size: 16px;
        position: absolute;
        color: #338BFE;
        top: 27px;
        line-height: 20px;
      }
      .bg1 {
        background: url("#{$cdn_dz_path}/td/tdvip_xiala1.svg") no-repeat;
        width: 38px;
        height: 38px;
      }
      .bg2 {
        background: url("#{$cdn_dz_path}/td/tdvip_xiala2.svg") no-repeat;
        width: 38px;
        height: 38px;
      }
      .bg3 {
        background: url("#{$cdn_dz_path}/td/tdvip_xiala3.svg") no-repeat;
        width: 38px;
        height: 38px;
      }
      .bg4 {
        background: url("#{$cdn_dz_path}/td/tdvip_xiala4.svg") no-repeat;
        width: 38px;
        height: 38px;
      }
      .bg5 {
        background: url("#{$cdn_dz_path}/td/tdvip_xiala5.svg") no-repeat;
        width: 38px;
        height: 38px;
      }
      .bg6 {
        background: url("#{$cdn_dz_path}/td/tdvip_xiala6.svg") no-repeat;
        width: 38px;
        height: 38px;
      }
      .bg7 {
        background: url("#{$cdn_dz_path}/td/tdvip_xiala7.svg") no-repeat;
        width: 38px;
        height: 38px;
      }
      .bg8 {
        background: url("#{$cdn_dz_path}/td/tdvip_xiala8.svg") no-repeat;
        width: 38px;
        height: 38px;
      }
    }
  }
}
@media (max-width: 1280px){
  .userTypeBox:global(.dropdownWrapper){
    height: 400px;
    border-radius: 6px;
    width: 380px;
    margin-left: -314px;
    text-align: center;

    &::before{
      content: " ";
      border: 8px solid transparent;
      border-bottom: 8px solid #e2e2e2;
      width: 0;
      height: 0;
      position: absolute;
      top: -7px;
      left: 52%;
      filter:drop-shadow(0px -1px 0px  rgba(0, 0, 0, 0.1));
      border-radius: 21%;
      margin-left: 104px;
    }
    .more {
      a {
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        color: #111111 !important;
        line-height: 17px;
        display: block;
      }
    }
  }
}
