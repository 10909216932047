$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
.hoverUnderline{
  display: inline-block;
  width: 270px;
  &:hover{
    text-decoration: underline;
  }
}
//.collect{
//  div{
//    width: 100%;
//    overflow: hidden;
//    text-overflow: ellipsis;
//    white-space: nowrap;
//    cursor: pointer;
//  }
//  p{
//    cursor: pointer;
//
//  }
//  span{
//    color: #ff4d4d;
//    text-decoration: underline;
//  }
//}
//.downloadBox {
//  width: 100%;
//  .download {
//    width: 100%;
//    text-align: center;
//
//    .title {
//      width: 100%;
//      overflow: hidden;
//      font-size: 14px;
//      text-overflow: ellipsis;
//      white-space: nowrap;
//    }
//
//    .vipTip {
//      width: 350px;
//      font-size: 14px;
//      p{
//        img{
//          margin-right: 8px;
//          margin-bottom: -2px;
//          //margin-left: -27px;
//        }
//      }
//      span{
//        color: #FF4D4D;
//        border-bottom: 1px solid #FF4D4D ;
//      }
//    }
//  }
//}