@charset "UTF-8";
.font, .kwInput {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important; }

.oneline, .SearchInputWrapper {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis; }

.svgGray {
  fill: #767676; }

.svgRed {
  fill: #e60023; }

.font-regular {
  font-family: "SourceHanSansSC-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-medium, .kwInput::-webkit-input-placeholder, .kwInput:-moz-placeholder, .kwInput::-moz-placeholder, .kwInput:-ms-input-placeholder {
  font-family: "SourceHanSansSC-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-bold {
  font-family: "SourceHanSansSC-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.SearchTop {
  z-index: 500;
  position: relative;
  width: 100vw; }

:global(.el-popup-parent--hidden) .SearchTop.fixed {
  right: 17px; }

.SearchWrapper {
  box-sizing: content-box; }

.SearchArea {
  height: 80px;
  background: transparent;
  display: flex;
  align-items: center;
  z-index: 102;
  position: relative;
  padding-left: 46px;
  padding-right: 45px; }
  .SearchArea :global(.center) {
    display: flex;
    flex: 1; }
    .SearchArea :global(.center) .da_logo {
      background: url("https://dz-img.bigbigwork.cn/pl/pinilte_logo_h.svg ") center center no-repeat; }
      .SearchArea :global(.center) .da_logo::after {
        content: ' ';
        background: none; }

.SearchTop.fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  background: #fff; }

.SearchTop.active {
  background: #fff; }

.advice {
  font-family: '思源黑体 CN Medium';
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  color: #8d8d8d;
  padding-left: 32px;
  cursor: pointer; }
  .advice .qrcodeWrapper {
    width: 180px;
    top: 32px;
    margin-left: -138px; }
    .advice .qrcodeWrapper::before {
      margin-left: 48px; }
  .advice .qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    flex-direction: column; }
    .advice .qrcode img {
      width: 100px;
      height: 100px; }
    .advice .qrcode span {
      font-size: 12px;
      color: #8d8d8d;
      margin: 4px auto 16px auto;
      line-height: 12px; }
    .advice .qrcode p {
      font-size: 14px;
      color: #333;
      line-height: 14px;
      white-space: nowrap; }
  .advice:hover .text {
    color: #333;
    text-decoration: underline; }

.nav {
  height: 48px;
  border-radius: 24px;
  display: block;
  line-height: 48px;
  margin: 0 10px 0 8px;
  color: #333;
  font-weight: bold;
  text-align: center;
  padding: 0 12px;
  font-size: 16px; }
  .nav:hover {
    background: #efefef;
    color: #333; }

.pinLiteLogo {
  width: 82px;
  margin-left: 0;
  height: 48px;
  background-size: 48px 48px;
  background-image: url("https://dz-img.bigbigwork.cn/p/pinilte_logo_w.svg");
  background-repeat: no-repeat;
  background-position: left center;
  position: relative; }
  .pinLiteLogo::after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 33px;
    height: 11px;
    background-image: url("../../../assets/images/pinilte_logo_test.svg");
    background-repeat: no-repeat;
    background-position: left center; }

.toHome {
  width: 60px; }

.SearchInputWrapper {
  height: 48px;
  background: #EFEFEF;
  border-radius: 24px;
  position: absolute;
  left: 0;
  right: 0;
  line-height: 48px;
  padding: 0 64px 0 16px; }
  .SearchInputWrapper:hover {
    background-color: #e1e1e1; }
  .SearchInputWrapper.active {
    box-shadow: 0 0 0 4px #8AC4FF; }

.searchBlock {
  flex: 1;
  position: relative; }

.searchPlaceHolder {
  position: absolute;
  top: 50%;
  color: #8d8d8d;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 16px;
  transform: translateY(-50%); }
  .searchPlaceHolder .shuLine {
    width: 1px;
    height: 16px;
    margin: 0 8px 0 12px;
    background: #333333; }
  .searchPlaceHolder .placeholderCss {
    margin-left: 25px;
    font-size: 16px;
    color: #767676; }
  .searchPlaceHolder .Ptitle {
    font-weight: bold;
    font-size: 16px;
    color: #333333; }

.kwInput {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  outline: none;
  background: transparent;
  width: calc(100% - 100px);
  color: #333;
  line-height: 40px !important;
  height: 40px;
  padding-left: 0px;
  position: absolute;
  left: 48px;
  top: 4px;
  z-index: 1;
  border-radius: 0; }
  .kwInput::-webkit-input-placeholder {
    color: #767676;
    text-indent: 5px;
    font-size: 16px; }
  .kwInput:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #767676;
    text-indent: 5px;
    font-size: 16px; }
  .kwInput::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #767676;
    text-indent: 5px;
    font-size: 16px; }
  .kwInput:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #767676;
    font-size: 16px;
    text-indent: 5px; }

.reset {
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 100%;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -16px;
  justify-content: center;
  align-items: center;
  z-index: 13; }
  .reset:hover {
    background-color: rgba(0, 0, 0, 0.06); }

.keyword {
  opacity: 0;
  font-size: 16px;
  font-weight: bold;
  padding-left: 32px;
  display: inline-block;
  color: #767676;
  max-width: 80%; }

.translateLabel {
  margin-left: 56px; }

.translateWord {
  color: #767676;
  font-size: 16px;
  z-index: 10;
  position: relative; }

.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5); }

.dropDown {
  position: absolute;
  background: #fff;
  left: 0;
  right: 0;
  top: 52px;
  border-radius: 0 0 8px 8px;
  padding: 0 0;
  z-index: 103; }

.btn {
  width: 110px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 30px;
  margin: 0 10px;
  font-size: 16px; }

.btn.login {
  border-radius: 30px;
  color: #333;
  display: block;
  border: 1px solid #333333;
  margin-right: 0;
  background: #fff; }
  .btn.login:hover {
    background: #e6e6e6; }

.btn.register {
  background: #FF4D4D;
  color: #fff;
  margin-left: 20px;
  display: block; }
  .btn.register:hover {
    background: #ff1a1a; }
