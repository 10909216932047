@charset "UTF-8";
.font-regular {
  font-family: "SourceHanSansSC-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-medium {
  font-family: "SourceHanSansSC-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-bold, .header-vip-tips {
  font-family: "SourceHanSansSC-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.header-vip-tips {
  position: absolute;
  height: 17px;
  white-space: nowrap;
  background: #1BCB0B;
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 5px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  transform: scale(0.75);
  transform-origin: 0;
  top: -17px;
  left: 16px;
  line-height: 17px;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-iteration-count: 1; }
  .header-vip-tips:after {
    position: absolute;
    left: 10px;
    bottom: -5px;
    display: block;
    content: " ";
    width: 9px;
    height: 5px;
    background-image: url("../../../assets/images/img_triangle.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%; }

@keyframes shakeX {
  from,
  to {
    transform: scale(0.75) translate3d(0, 0, 0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(0.75) translate3d(-2px, 0, 0); }
  20%,
  40%,
  60%,
  80% {
    transform: scale(0.75) translate3d(2px, 0, 0); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75); }
  50% {
    -webkit-transform: scale3d(0.87, 0.87, 0.87);
    transform: scale3d(0.87, 0.87, 0.87); }
  to {
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75); } }

.ani-shakeX :local {
  animation-name: shakeX; }

.ani-scale :local {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }
