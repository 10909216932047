$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
.circle-guide-box-V1 {
  .guide-content-word-v1 {
    z-index:99;
    padding: 6px 16px;
    white-space: nowrap;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: -57px;
    transform: translateX(-50%);
    box-sizing: border-box;
    background-color: #E67E22;
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    line-height: 16px;

    span {
      display: inline-block;
      width: max-content;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -16px;
      width: 0;
      height: 0;
      border-width: 11px;
      border-style: solid;
      border-color: transparent transparent #E67E22 transparent;
    }
  }

  .circle-guide-v1 {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
    border: 4px solid #FF4D4D;
    border-radius: 50%;
    -webkit-animation-name: 'ripple-v1';
    -webkit-animation-duration: 4.5s;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-animation-iteration-count: infinite; // -webkit-animation-direction: alternate;
    pointer-events: none;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ripple-v1 {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  25% {
    transform: scale(1.3);
    opacity: 1;
  }
  50% {
    transform: scale(1); // opacity: 0.8;
    opacity: 1;
  }
  75% {
    transform: scale(1.3);
    opacity: 1;
  }
  /*95% {*/
  /*  transform: scale(1);*/
  /*  border-color: #ea0000;*/
  /*}*/
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
