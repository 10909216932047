$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
@import "~bbw-components/styles/params";
$assets-path:"~bbw-components/assets";

.userTypeBox:global(.dropdownWrapper){
  height: 400px;
  border-radius: 6px;
  width: 380px;
  margin-left: -230px;
  text-align: center;

  &::before{
    content: " ";
    border: 8px solid transparent;
    border-bottom: 8px solid #e2e2e2;
    width: 0;
    height: 0;
    position: absolute;
    top: -7px;
    left: 52%;
    filter:drop-shadow(0px -1px 0px  rgba(0, 0, 0, 0.1));
    border-radius: 21%;
    margin-left: 21px;
  }
  .more {
    a {
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      color: #111111 !important;
      line-height: 17px;
      display: block;
    }
  }
}
.dropdownMain:global(.dropdownMain){
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0 0 12px 0;
  border-radius: 8px;
}
.top-content {
  background-image: url("#{$cdn_dz_path}/p/top-content.png");
  background-repeat: no-repeat;
  background-color: #e2e2e2 ;
  width: 100%;
  height: 148px;
  padding: 17px 18px;
  background-size: cover;
  button {
    border: none;
    margin-top: 18px;
    width: 340px;
    height: 42px;
    background: rgba(255, 77, 77, 1);
    box-shadow: 0px 2px 4px 0px rgba(136, 9, 9, 0.7);
    border-radius: 4px;
    &:hover{background: rgba(255, 77, 77, 1);}
    p {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 16px;
      position: relative;
      a {
        background: url("../../../assets/images/freetime.png");
        font-size: 12px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1) !important;
        line-height: 20px;
        position: absolute;
        background-size: 100%;
        width: 112px;
        height: 32px;
        top: -24px;
        //background-image: url("#{$cdn_xcx_path}/p/newVip/new_freetime1.png");
        //font-size: 14px;
        //font-weight: 500;
        //color: rgba(255, 255, 255, 1) !important;
        //line-height: 24px;
        //position: absolute;
        //width: 130px;
        //height: 34px;
        //top: -20px;
        //background-size: cover;
        //right: 5px;
        //background-repeat: no-repeat;
        //text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.usetype {
  text-align:center;
  p {
    font-size: 22px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 22px;

    span {
      color: #FF4D4D;
    }
  }

  .smallTip {
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 16px;
    margin-top: 19px;
  }
}
.bottom-content {
  .title {
    margin-top: 14px;
    p {
      font-size: 16px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 22px;
    }
    .smalltitle {
      margin-top: 6px;
      font-size: 10px;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 14px;
    }
  }
  .wrapList {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-wrap: wrap;
    padding: 0 14px;
    .item {
      width: 76px;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 11px;
      position: relative;
      p {
        margin-top: 5px;
        font-size: 12px;
        //font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 12px;
        white-space: nowrap;
      }
      .constr {
        display: inline-block;
        transform: scale(0.5);
        margin-top: 6px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(118, 118, 118, 1);
        line-height: 10px !important;
        white-space: nowrap;
      }

      .on_fun{
        width: 68px;
        height: 20px;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(255, 77, 77, 0.2);
        border-radius: 2px;
        margin-top: 0;
        transform: scale(0.5);
        font-size: 16px;
        position: absolute;
        color: #ff4d4d;
        top: 26px;
        line-height: 20px;
      }

    }
  }
}
.rights{
  display: -webkit-flex;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  fill: #FF4D4D;
}

.flwsx{
  background-image: url("#{$cdn_xcx_path}/p/newVip/vip_xiala1.svg");
}
.wyb{
  background-image: url("#{$cdn_xcx_path}/p/newVip/newVip_sm_icon2.png");
}
.ydb{
  background-image: url("#{$cdn_dz_path}/p/tq_ydb.svg");
}
.wxxz{
  background-image: url("#{$cdn_xcx_path}/p/newVip/newVip_sm_icon3.png");
}
.wxsc{
  background-image: url("#{$cdn_dz_path}/p/tq_wxsc.svg");
}
.gsxz{
  background-image: url("#{$cdn_xcx_path}/p/newVip/vip_xiala7.svg");
}
.wxshch{
  background-image: url("#{$cdn_dz_path}/p/tq_wxshch.svg");
}
.wxcj{
  background-image: url("#{$cdn_dz_path}/p/tq_wxcj.svg");
}
.xzrs99{
  background-image: url("#{$cdn_dz_path}/p/tq_xzrs99.svg");
}
.zsbe{
  background-image: url("#{$cdn_xcx_path}/p/newVip/vip_xiala4.svg");
}
.zspin{
  background-image: url("#{$cdn_xcx_path}/p/newVip/vip_xiala3.svg");
}
.wxsc{
  background-image: url("#{$cdn_xcx_path}/p/newVip/vip_xiala5.svg");
}
.wxxzdatu{
  background-image: url("#{$cdn_xcx_path}/p/newVip/vip_xiala6.svg");
}


@media (max-width: 1280px){
  .userTypeBox:global(.dropdownWrapper){
    height: 400px;
    border-radius: 6px;
    width: 380px;
    margin-left: -314px;
    text-align: center;

    &::before{
      content: " ";
      border: 8px solid transparent;
      border-bottom: 8px solid #e2e2e2;
      width: 0;
      height: 0;
      position: absolute;
      top: -7px;
      left: 52%;
      filter:drop-shadow(0px -1px 0px  rgba(0, 0, 0, 0.1));
      border-radius: 21%;
      margin-left: 104px;
    }
    .more {
      a {
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        color: #111111 !important;
        line-height: 17px;
        display: block;
      }
    }
  }
}


//.wxsc{
//  background-image: url("#{$cdn_dz_path}/p/tq_wxsc.svg");
//}


