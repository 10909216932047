$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
:global(.el-dialog.cDialogPaySuccessImg){
  position: absolute;
  left:0;top:0;right:0;bottom:0;
  border-radius:5px !important;
  text-align: center;
  width: auto;
  background: none;
  padding:0;
  margin: 0;
  box-shadow: none;
  :global(.el-dialog__header){
    height: 0;
    padding: 0;
    :global(.el-dialog__headerbtn){
      top: 6.25vw;
      right: 6.25vw;
      position: fixed;
      z-index: 1000;
      :global(.el-dialog__close)::before {
        background: url('#{$cdn_xcx_path}/p/code/vip_success_close.png');
      }
    }
  }
  :global(.el-dialog__body){
    margin: 0;
    padding:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :global(.el-dialog__close)::after {
    //content: '仅此一次，关闭即放弃福利。';
    display: block;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    left: -208px;
    width: 144px;
    font-size: 16px;
    font-weight: 500;
    color: #AAAAAA;
    line-height: 24px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    white-space: nowrap;
  }
  :global(.el-dialog__headerbtn){

  }
  .CenterPromptbox { padding-bottom: 68px;}
}
.pay-success {
  display: flex;
  .pay-success-img {
    max-width: 100vw;
    max-height: 100vh;
    object-fit: contain;
    //cursor: pointer;
  }
}
