$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";

.headerNavBar {
  padding: 8px 60px 8px 62px;


  .marginLeft {
    margin-left: 12px !important;

    .goHome {
      padding-left: 12px;
      color: #333333;
      font-size: 16px;
      line-height: 24px;


    }
  }
}

.mobile_header {
  margin-left: 13px !important;
  font-size: 16px;
  color: #333333;
  position: relative;
  cursor: pointer;
  top: 1px;

  &:hover {
    color: #FF4D4D;
  }

  img {
    position: absolute;
    top: -8px;
    left: 43px;
  }

  @-webkit-keyframes shakeX {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }
  @keyframes shakeX {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    0.3%,
    0.9%,
    1.6%,
    2.4%,
    3.2% {
      -webkit-transform: translate3d(-1px, 0, 0);
      transform: translate3d(-1px, 0, 0);
    }

    0.6%,
    1.2%,
    2.0%,
    2.8% {
      -webkit-transform: translate3d(1px, 0, 0);
      transform: translate3d(1px, 0, 0);
    }
    3.6%, 100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  //.animate__shakeX {
  //  animation: shakeX 30s infinite;
  //  animation-delay:3s;
  //}
}

:global(.c-header){
  .c-header-l {
    margin-left: 0;
    float: left;
  }

  :global(.c-header-r) {
    margin-right: 1px;
    float: right;
    :global(.c-header-li) {
      float: left;
      margin-left: 1px ;
    }

  }

}
@media (max-width: 1279px) {
  :global(.c-header){


    :global(.c-header-r) {
      margin-right: -57px;
      float: right;


    }

  }
}







