@charset "UTF-8";
.font {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important; }

.oneline {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis; }

.svgGray {
  fill: #767676; }

.svgRed {
  fill: #e60023; }

.hotWordsBox {
  padding: 7px 20px 24px; }
  .hotWordsBox .hotWordsTitle {
    line-height: 21px;
    font-size: 14px;
    color: #333; }
  .hotWordsBox .hotWordsList {
    margin-top: 8px;
    display: flex; }
    .hotWordsBox .hotWordsList .hotWordsItem {
      margin-right: 8px;
      padding: 0px 16px;
      height: 34px;
      line-height: 34px;
      background: #f0f0f0;
      font-size: 16px;
      color: #333;
      border-radius: 17px;
      cursor: pointer; }
      .hotWordsBox .hotWordsList .hotWordsItem:hover {
        background-color: #DADADA; }
