@charset "UTF-8";
.cl:after {
  content: "";
  display: block;
  clear: both; }

.ani {
  transition: all 0.5s ease; }

.font-regular, .menu:global(.dropdownWrapper) {
  font-family: "SourceHanSansSC-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-medium {
  font-family: "SourceHanSansSC-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-bold {
  font-family: "SourceHanSansSC-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

:global(.dropdownWrapper).menu {
  transform: translateX(-36px);
  width: 154px;
  left: 79px;
  top: 24px; }
  :global(.dropdownWrapper).menu::before {
    content: " ";
    border: 8px solid transparent;
    border-bottom: 8px solid #fff;
    width: 0;
    height: 0;
    position: absolute;
    top: -7px;
    left: 63px;
    margin-left: -14px;
    filter: drop-shadow(0px -1px 0px rgba(0, 0, 0, 0.1)); }
  :global(.dropdownWrapper).menu.middle {
    left: 58px; }
    :global(.dropdownWrapper).menu.middle::before {
      left: 70px; }
  @media (max-width: 1279px) {
    :global(.dropdownWrapper).menu.xs {
      left: 4px; }
      :global(.dropdownWrapper).menu.xs::before {
        left: 94px; }
    :global(.dropdownWrapper).menu.middle {
      left: 1px; }
      :global(.dropdownWrapper).menu.middle::before {
        left: 84px; } }

.menu {
  right: 0;
  padding: 0; }
  .menu::before {
    right: 50%;
    margin: 0;
    transform: translateX(45px); }

.menuItem {
  position: relative;
  display: block;
  height: 47px;
  line-height: 44px;
  text-align: left;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  text-indent: 47px; }
  .menuItem .notifiNum {
    font-size: 12px;
    color: #FF4D4D;
    position: absolute;
    right: 16px; }
  .menuItem .icon {
    display: flex;
    width: 14px;
    height: 14px;
    line-height: 18px;
    position: absolute;
    left: 25px;
    top: 47%;
    transform: translateY(-50%); }
    .menuItem .icon.invite_icon {
      width: 16px; }
    .menuItem .icon.hasBorder {
      padding: 1px;
      border-radius: 50%;
      border: 1px solid #767676;
      overflow: hidden;
      width: 18px;
      height: 18px;
      top: 22px;
      left: 23px; }
      .menuItem .icon.hasBorder > img {
        border-radius: 50%;
        overflow: hidden; }
    .menuItem .icon > img {
      width: 100%;
      vertical-align: bottom; }
  .menuItem .image {
    display: inline-block; }
  .menuItem .imageHover {
    display: none; }
  .menuItem:hover {
    background: #F0F0F0; }
    .menuItem:hover::after {
      display: none; }
    .menuItem:hover .image {
      display: none; }
    .menuItem:hover .imageHover {
      display: inline-block; }
    .menuItem:hover .icon.hasBorder {
      border: 1px solid #333333; }
  .menuItem::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 122px;
    left: 15px;
    background-color: #F0F0F0;
    top: -2px; }
  .menuItem a {
    color: #333333;
    display: block; }
    .menuItem a:hover {
      color: #333333; }
      .menuItem a:hover.hasBorder {
        border: 1px solid #333333; }

.menuItem .icon {
  background-position: center center;
  background-repeat: no-repeat;
  height: 14px; }
  .menuItem .icon.xx {
    background-image: url("../../../assets/images/icon_header_notification.svg"); }
  .menuItem .icon.yq {
    background-image: url("../../../assets/images/icon_header_invite.svg"); }
  .menuItem .icon.sz {
    background-image: url("../../../assets/images/icon_header_settings.svg"); }
  .menuItem .icon.rz {
    background-image: url("../../../assets/images/icon_header_designer.svg"); }
  .menuItem .icon.bz {
    background-image: url("../../../assets/images/icon_header_help.svg"); }
  .menuItem .icon.tc {
    background-image: url("../../../assets/images/icon_header_exit.svg"); }
