@charset "UTF-8";
.f-r, .gray-reg, #msg-box .secondary-box .secondary-cont ul li, #msg-box .secondary-box .secondary-cont ul li.msg-system .sys-link {
  font-family: "SourceHanSansSC-Regular", "Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: 400; }

.pointer:hover {
  cursor: pointer; }

.gray-reg, #msg-box .secondary-box .secondary-cont ul li {
  font-size: 14px;
  color: #666666 !important; }

.black-m {
  line-height: initial;
  font-size: 14px;
  line-height: 14px;
  font-family: "SourceHanSansCN-Medium", "SourceHanSansSC-Medium","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: 500;
  color: #393939 !important;
  margin: 0 2px; }

a.black-m:hover {
  color: #000 !important; }

.bottom-line, #msg-box .first-level-title {
  border-bottom: 1px solid #F0F0F0; }

.font-medium, #msg-box .secondary-box .title {
  font-family: "SourceHanSansCN-Medium", "SourceHanSansSC-Medium", "Microsoft Yahei", "微软雅黑", sans-serif; }

#msg-box {
  text-align: center;
  cursor: auto;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto;
  position: absolute;
  width: 314px;
  height: 440px;
  border-radius: 6px;
  background-color: transparent;
  z-index: 1000;
  /*.secondary-box-*/ }
  #msg-box::before {
    content: " ";
    border: 8px solid transparent;
    border-bottom: 8px solid #fff;
    width: 0;
    height: 0;
    position: absolute;
    top: -7px;
    left: 52%;
    margin-left: -14px;
    filter: drop-shadow(0px -1px 0px rgba(0, 0, 0, 0.1)); }
  #msg-box #msg-cont {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    border-radius: 6px;
    z-index: -1;
    padding: 0; }
  #msg-box .first-level-title {
    height: 48px;
    line-height: 48px;
    text-indent: 2px;
    font-size: 16px;
    font-weight: 600;
    color: #393939; }
  #msg-box .first-level-content ul > li {
    text-align: left;
    font-size: 14px;
    padding: 0 23px;
    font-weight: 400;
    cursor: pointer;
    border: none !important;
    color: #333333;
    height: 47px;
    line-height: 46px;
    display: flex;
    position: relative; }
    #msg-box .first-level-content ul > li .li-cont {
      padding-left: 8px; }
    #msg-box .first-level-content ul > li .unread-count {
      font-size: 12px;
      color: #FF4D4D;
      margin-left: 4px; }
    #msg-box .first-level-content ul > li img {
      width: 14px; }
      #msg-box .first-level-content ul > li img:nth-child(1) {
        display: inline-block; }
      #msg-box .first-level-content ul > li img:nth-child(2) {
        display: none; }
    #msg-box .first-level-content ul > li::after {
      position: absolute;
      bottom: -1px;
      background-color: #F0F0F0;
      content: '';
      width: 284px;
      height: 1px;
      left: 15px;
      top: -1px; }
    #msg-box .first-level-content ul > li:hover {
      background-color: #F0F0F0 !important;
      color: #333333; }
      #msg-box .first-level-content ul > li:hover img {
        width: 14px; }
        #msg-box .first-level-content ul > li:hover img:nth-child(1) {
          display: none; }
        #msg-box .first-level-content ul > li:hover img:nth-child(2) {
          display: inline-block; }
      #msg-box .first-level-content ul > li:hover::after {
        display: none; }
  #msg-box .secondary-box {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    border-radius: 6px;
    cursor: default; }
    #msg-box .secondary-box .title {
      height: 48px;
      font-size: 16px;
      font-weight: 600;
      color: #393939;
      line-height: 48px;
      position: relative;
      border-bottom: 1px solid #F0F0F0; }
      #msg-box .secondary-box .title .close-icon {
        cursor: pointer;
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 58px;
        height: 30px;
        background: url("../../../assets/images/not_back_v3.png") 24px center no-repeat;
        background-size: 12px 12px; }
        #msg-box .secondary-box .title .close-icon:hover {
          background: url("../../../assets/images/not_back_hover_v3.png") 24px center no-repeat;
          background-size: 12px 12px; }
      #msg-box .secondary-box .title .view-us-coopreation {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        font-size: 12px;
        font-family: SourceHanSansCN-Bold,SourceHanSansCN;
        font-weight: bold;
        color: #AAAAAA; }
        #msg-box .secondary-box .title .view-us-coopreation:hover {
          color: #333333; }
    #msg-box .secondary-box .secondary-cont {
      color: #666;
      text-align: left; }
      #msg-box .secondary-box .secondary-cont ul {
        padding: 0 24px; }
      #msg-box .secondary-box .secondary-cont ul li {
        border-bottom: 1px solid #F0F0F0;
        padding: 24px 0 16px 0;
        display: flex;
        position: relative; }
        #msg-box .secondary-box .secondary-cont ul li .cnt-noti {
          display: flex; }
        #msg-box .secondary-box .secondary-cont ul li.no-read::before {
          content: '';
          background-color: #FF4D4D;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          left: -8px;
          top: 16px;
          position: absolute; }
        #msg-box .secondary-box .secondary-cont ul li .ava-box {
          width: 32px;
          margin-right: 8px; }
          #msg-box .secondary-box .secondary-cont ul li .ava-box .avatar {
            display: inline-block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden; }
            #msg-box .secondary-box .secondary-cont ul li .ava-box .avatar img {
              width: 100%;
              vertical-align: bottom; }
          #msg-box .secondary-box .secondary-cont ul li .ava-box .avatar.square {
            border-radius: 2px; }
        #msg-box .secondary-box .secondary-cont ul li .cop-cont {
          word-break: break-all;
          word-wrap: break-word;
          line-height: 17px;
          transform: translateY(0px);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical; }
        #msg-box .secondary-box .secondary-cont ul li .black-mdesign-status {
          color: #333;
          font-weight: 500;
          font-family: "SourceHanSansSC-Medium", "Microsoft Yahei", "\5FAE\8F6F\96C5\9ED1", sans-serif;
          font-size: 14px;
          line-height: 14px;
          margin-top: 2px; }
        #msg-box .secondary-box .secondary-cont ul li .crt-status {
          display: block;
          color: #5BB6FF !important;
          font-size: 14px;
          line-height: 14px;
          font-family: "SourceHanSansSC-Regular", "Microsoft Yahei", "微软雅黑", sans-serif; }
        #msg-box .secondary-box .secondary-cont ul li .design-status {
          line-height: 14px; }
        #msg-box .secondary-box .secondary-cont ul li .push-time {
          margin-top: 2px;
          height: 16px;
          line-height: initial;
          font-size: 10px;
          color: #AAAAAA;
          font-family: "SourceHanSansSC-Medium", "Microsoft Yahei", "微软雅黑", sans-serif; }
        #msg-box .secondary-box .secondary-cont ul li.msg-system .msg-system-title {
          letter-spacing: 1px;
          -webkit-line-clamp: initial; }
        #msg-box .secondary-box .secondary-cont ul li.msg-system .msg-system-detail {
          background-color: #F0F0F0;
          padding: 8px;
          border-radius: 4px;
          line-height: 21px;
          word-break: break-all;
          word-wrap: break-word;
          margin-top: 6px; }
        #msg-box .secondary-box .secondary-cont ul li.msg-system .sys-link {
          display: block;
          color: #5BB6FF; }
          #msg-box .secondary-box .secondary-cont ul li.msg-system .sys-link:hover {
            text-decoration: underline; }
        #msg-box .secondary-box .secondary-cont ul li.linkBoxHover {
          cursor: pointer; }
          #msg-box .secondary-box .secondary-cont ul li.linkBoxHover:hover .system-content .msg-system-detail {
            background: #E5E5E5; }
        #msg-box .secondary-box .secondary-cont ul li .system-content {
          width: 100%; }
        #msg-box .secondary-box .secondary-cont ul li:hover .del-this {
          display: inline-block;
          cursor: pointer; }
        #msg-box .secondary-box .secondary-cont ul li .del-this {
          display: none;
          width: 14px;
          height: 14px;
          background: url("../../../assets/images/msg-del_v3.png") center center no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 0; }
          #msg-box .secondary-box .secondary-cont ul li .del-this:hover {
            background: url("../../../assets/images/msg-del_hover_v3.png") center center no-repeat;
            background-size: 100% 100%; }
  #msg-box .noti-box-leave-active {
    transition: all .3s ease; }
  #msg-box .noti-box-leave, #msg-box .noti-box-leave-to {
    transform: translateX(315px);
    /*opacity: 0;*/ }
  #msg-box .secondary-box-enter-active {
    transition: all .3s ease; }
  #msg-box .secondary-box-leave-active {
    /*transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);*/
    transition: all .3s ease; }
  #msg-box .secondary-box-enter, #msg-box .secondary-box-to {
    transform: translateX(315px);
    /*opacity: 0;*/ }
  #msg-box .secondary-box-leave, #msg-box .secondary-box-leave-to {
    transform: translateX(315px);
    /*opacity: 0;*/ }
