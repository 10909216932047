$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
.recommendedDropdown{
  position: absolute; background: #fff;
  left:0;right:0;top:64px;
  border-radius: 0 0 8px 8px;
  padding: 16px 0 ;
  z-index: 101;
}
.recommendedList{

}
.recommendedWrapper{
  padding: 12px 0 16px;
  .recommendBoards{
    .recommendTitle{
      padding:0 48px;
      margin-top: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
    }
    .recommendBoardsItem{
      height: 60px;
      padding: 8px 48px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .BoardsImg{
        position: relative;
        width: 100px;
        height: 44px;
        background: rgba(0, 0, 0, 0.02);
        border-radius: 6px;
        overflow: hidden;
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 6px;
          transform: translate(-50%, -50%);
          display: block;
          width: 100%;
        }
      }
      .BoardsInfo{
        margin-left: 8px;
        height: 100%;
        .BoardsInfoTitle{
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
        }
        .BoardsInfoDesc{
          font-size: 12px;
          font-weight: 400;
          color: #767676;
          line-height: 18px;
        }
      }
      &:hover, &.hover {
        background-color: #F0F0F0;
      }
    }
    .moreBoards{
      padding:0 70px;
      color: #767676;
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      cursor: pointer;
      background: url('../../../assets/images/skateArrow.svg') 48px 10px no-repeat;
      background-size: 14px 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover, &.hover {
        background-color: #F0F0F0;
      }
    }
  }
}
.recommendedWord{
  display: block;
  font-size:16px;
  line-height:26px;
  height: 26px;
  margin-bottom: 4px;
  cursor: pointer;
  a{
    padding-left: 48px;
    color: #333333;
    display: block;height: 100%;
  }
  &.hover,&:hover{
    background: #EFEFEF;
  }
  .bold{
    font-weight:700;
  }
}
.input{
  height:0;
  //font-size: 0;
  line-height: 0;
  padding: 0;
  position: absolute;
}
