$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
:global(.el-dialog).dialogBehance{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  :global(.el-dialog__body){
    padding-top: 45px;
  }
  :global(.el-dialog__header){
    height: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 200;
  }
}
.ToBehance {
  .CenterPromptbox {
    width: 520px;
    padding: 45px 55px 32px 55px !important;
    text-align: center;
    overflow: visible;
    min-height: auto;
    .LOGO {
      position: relative;
      top: -51px;
    }

  }
  .Versionfont{
    display: block;
    margin-top: 70px;
    font-size: 18px;
    font-family: SourceHanSansCN-Bold;
    font-size: 18px;
    color: #303030;
    letter-spacing: 0.35px;
    text-align: center;
  }
  .hoverbd{
    a:hover {
      color:red !important ;
    }
  }
  div.icon-box{
    position: absolute;
    top: -47px;
    left: 50%;
    transform: translateX(-50%);
    width: 168px;
    text-align: center;
    img{
      display: inline-block;

    }
    img.pinterest-logo{
      width: 85px;
    }
    img.pinterest-word{
      margin-top: 19px;
      width: 168px;
      transform: translateY(-8px);
    }
  }

  div.down-word {
    overflow: hidden;
    padding-top: 10px;
    font-size: 15px;
    font-weight: 500;
    /*width: 510px;*/
    margin: 0 auto;
    a {
      position: relative;
      color: #303030 !important;
      padding-left: 30px;
      line-height: 20px;
      &::before {
        width: 17.7px;
        height: 16px;
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: url("#{$cdn_xcx_path}/pimg/client_download.png") center center no-repeat;
        background-size: 100%;
        left: 0;
      }
    }
    a:hover {
      color: #FF4D4D !important;
      &::before {
        background: url("#{$cdn_xcx_path}/pimg/client_download_hover.png") center center no-repeat;
        background-size: 100%;
      }
    }
  }
}
.Versionbuttonbox{
  text-align: center;
}
.Versionbutton.IsOk {
  cursor: pointer;
  background-color: #fe4365;
  color: #fff;
  border-color: #fe4365;
  border-radius: 5px;
}
.Versionbuttons {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  min-width: 200px;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  margin: 0 auto;
  background-color: #ecf0f1;
  color: #7f8c8d;
  border-color: #ecf0f1;
  cursor: no-drop;
  &:hover{
    color: #7f8c8d!important;
  }
}
