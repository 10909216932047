@charset "UTF-8";
.font {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important; }

.oneline {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis; }

.svgGray, .clear {
  fill: #767676; }

.svgRed {
  fill: #e60023; }

.searchHistory {
  padding: 31px 20px 16px; }

.label {
  line-height: 16px;
  height: 16px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 6px; }

.clear {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  cursor: pointer;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .clear:hover {
    background: #efefef; }

.word {
  background: #EFEFEF;
  border-radius: 17px;
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  color: #333333;
  padding: 0 16px;
  display: inline-block;
  margin: 4px 4px;
  max-width: 192px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .word:first-child {
    margin-left: 0; }
  .word:last-child {
    margin-right: 0; }
  .word:hover {
    background: #DADADA;
    color: #333333; }
