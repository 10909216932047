@charset "UTF-8";
:global(.el-dialog.dialogOfferDesc) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  text-align: center; }
  :global(.el-dialog.dialogOfferDesc) :global(.el-dialog__header) {
    display: none; }
  :global(.el-dialog.dialogOfferDesc) :global(.el-dialog__body)::before {
    display: inline-block;
    content: '';
    position: absolute;
    font-size: 16px;
    color: #767676;
    font-weight: 400;
    left: 12px;
    top: 12px; }
  :global(.el-dialog.dialogOfferDesc) .CenterPromptbox.radius {
    width: 540px; }
    :global(.el-dialog.dialogOfferDesc) .CenterPromptbox.radius::before {
      display: inline-block;
      content: '温馨提示';
      position: absolute;
      font-size: 16px;
      color: #767676;
      font-weight: 400;
      left: 12px;
      top: 12px; }
  :global(.el-dialog.dialogOfferDesc) .title {
    font-size: 22px;
    color: #333;
    font-weight: 500;
    text-align: center;
    padding-top: 20px; }
  :global(.el-dialog.dialogOfferDesc) .sm-title {
    font-size: 14px;
    color: #333;
    font-weight: 400;
    padding-top: 6px; }
  :global(.el-dialog.dialogOfferDesc) .desc-content {
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 20px;
    width: 450px;
    height: 140px;
    display: flex;
    color: #fff; }
    :global(.el-dialog.dialogOfferDesc) .desc-content .effective-today {
      font-size: 22px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("https://xcx-img.bigbigwork.cn/pimg/offerone.png") center center no-repeat;
      width: 106px;
      height: 140px;
      background-size: 100% 100%; }
      :global(.el-dialog.dialogOfferDesc) .desc-content .effective-today span {
        display: inline-block;
        width: 20px; }
    :global(.el-dialog.dialogOfferDesc) .desc-content .offer-word {
      box-sizing: border-box;
      background: url("https://xcx-img.bigbigwork.cn/pimg/offertwo.png") center center no-repeat;
      background-size: 100% 100%;
      width: 452px;
      height: 140px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 24px;
      padding-top: 20px; }
      :global(.el-dialog.dialogOfferDesc) .desc-content .offer-word span:first-child {
        color: #fff;
        font-size: 16px; }
      :global(.el-dialog.dialogOfferDesc) .desc-content .offer-word span:nth-child(2) {
        font-size: 24px;
        font-weight: 600;
        line-height: 58px; }
  :global(.el-dialog.dialogOfferDesc) .btn-box {
    display: flex;
    width: 450px;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px;
    margin: 0 auto;
    margin-top: 36px; }
    :global(.el-dialog.dialogOfferDesc) .btn-box button {
      width: 180px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #A3A3A3;
      font-size: 18px;
      font-weight: 500;
      background-color: #D8D8D8;
      border: none;
      border-radius: initial; }
      :global(.el-dialog.dialogOfferDesc) .btn-box button.again {
        color: #fff;
        background-color: #FF4D4D; }

.offerdesc {
  color: red; }
