@charset "UTF-8";
.font-regular {
  font-family: "SourceHanSansSC-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-medium {
  font-family: "SourceHanSansSC-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-bold, .bbw-mask-btn button {
  font-family: "SourceHanSansSC-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.bbw-mask-btn button {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  background-color: transparent;
  color: #FFFFFF; }
  .bbw-mask-btn button img {
    width: 100%;
    height: 100%;
    vertical-align: bottom; }
  .bbw-mask-btn button img.hoverIcon {
    display: none; }
  .bbw-mask-btn button:hover .normalIcon {
    display: none; }
  .bbw-mask-btn button:hover .hoverIcon {
    display: inline-block; }
  .bbw-mask-btn button.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0; }
  .bbw-mask-btn button.mask:hover::after {
    display: none; }
