$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
//@charset "UTF-8";
@import "../../../styles/params";
:global(.el-dialog.dialogVip.dialogVip_datu_bg){
  :global(.el-dialog__body){
    background: url("../../../assets/images/img_dialog_vip_bg.svg") left top no-repeat;
    //margin-bottom: 46px;
  }
}
:global(.el-dialog.dialogVip.dialogVip_material_bg){
  :global(.el-dialog__body){
    position: relative;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 247px;
        height: 232px;
        background: url("../../../assets/images/img_dialog_vip_bg2.svg") left top no-repeat;
    }
  }
}
:global(.el-dialog.dialogVip){
  width:auto;
  border-radius:5px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: 3px;
  transform: translate(-50%, -50%);
  margin-bottom: 0;
  &:global(.dialogVipHandleChange){
    margin: 0 auto;
    transform: none !important;
    left: auto;
    top: 106px;
    position: relative;
    margin-bottom: 52px;
  }
  :global(div.el-dialog__header){
    padding: 0;
    height: 0;
    position: relative;
    z-index: 1;
    :global(button.el-dialog__headerbtn) {
      :global(.el-dialog__close::before){
        background: url("../../../assets/images/dialog_close_icon_2.svg") center center no-repeat;
        opacity: 0.8;
      }
      &:hover {
        :global(.el-dialog__close::before){
          background: url("../../../assets/images/dialog_close_icon_2.svg") center center no-repeat;
          opacity: 1;
        }
      }
    }
  }
  :global(.el-dialog__body){
    text-align: center;
    padding: 32px 20px 24px 20px;
    //margin-bottom: 46px;
  }
  .el-collapse-item__header{
    display: inline !important;
  }
    .con {
      position: relative;
      z-index: 1;
      margin-top: 17px;
      //margin-bottom: 20px;
      .vipcon {
        width: 100%;
        margin: 0 auto;
        //padding: 0 35.5px;
      }
      .payArea {
        margin: 19px auto 5px auto;
        position: relative;
        text-align: left;
        width: 724px;
        height: 222px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #E2E2E2;
      }
      .contact {
        padding-top: 5px;
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        position: relative;
        top: -4px;
        left: 14px;
        color: #666666;
        .all_buyVip{
          .seach_code{
            position: relative;
            left: -5px;
            height: 19px;
            display: inline-block;
            cursor: pointer;
            .bottom_hover_box{
              width: 136px;
              height: 144px;
              position: absolute;
              z-index: 10;
              padding-top: 30px;
              left: -39px;
              top: 18px;
              background: url("#{$cdn_xcx_path}/p/newVip/vip_code_bg.svg");
              display: none;
              img{
                width: 80px;
                margin-left: 0px;
              }
              span{
                display: inline-block;
                font-size: 12px;
                position: relative;
                top: -6px;
              }
            }
            &:hover{
              border-bottom: 1px solid #333333;
              color: #333333;
              .bottom_hover_box{
                display: block;
              }
            }
            .top_hover_box{
              width: 136px;
              height: 144px;
              position: absolute;
              z-index: 10;
              padding-top: 23px;
              left: -39px;
              top: -140px;
              background: url('#{$cdn_xcx_path}/p/newVip/vip_code_bg.svg');
              transform: rotate(180deg);
              display: none;
              img{
                width: 80px;
                transform: rotate(180deg);
                margin-right: 3px;
              }
              span{
                display: inline-block;
                font-size: 12px;
                position: relative;
                top: 0px;
                transform: rotate(180deg);
              }
            }
          }
        }
        .teacher_buyVip {
          margin-left: 12px;
          .seach_code{
            position: relative;
            left: -5px;
            height: 19px;
            display: inline-block;
            cursor: pointer;
            .bottom_hover_box{
              width: 136px;
              height: 144px;
              position: absolute;
              z-index: 10;
              padding-top: 30px;
              left: -39px;
              top: 18px;
              background: url("#{$cdn_xcx_path}/p/newVip/vip_code_bg.svg");
              display: none;
              img{
                width: 80px;
                margin-right: 0px;
              }
              span{
                display: inline-block;
                font-size: 12px;
                position: relative;
                top: -6px;
              }
            }
            &:hover{
              border-bottom: 1px solid #333333;
              color: #333333;
              .bottom_hover_box{
                display: block;
              }
            }
            .top_hover_box{
              width: 136px;
              height: 144px;
              position: absolute;
              z-index: 10;
              padding-top: 23px;
              left: -39px;
              top: -140px;
              background: url('#{$cdn_xcx_path}/p/newVip/vip_code_bg.svg');
              transform: rotate(180deg);
              display: none;
              img{
                width: 80px;
                transform: rotate(180deg);
                margin-right: 3px;
              }
              span{
                display: inline-block;
                font-size: 12px;
                position: relative;
                top: -0px;
                transform: rotate(180deg);
              }
            }
          }
        }
        @media (max-height:860px) {
          //上边显示
          .all_buyVip{
            .seach_code{
              &:hover{
                .bottom_hover_box{
                  display: none;
                }
                .top_hover_box{
                  display: block;
                }
              }
            }
          }
          .teacher_buyVip{
            .seach_code{
              &:hover{
                .bottom_hover_box{
                  display: none;
                }
                .top_hover_box{
                  display: block;
                }
              }
            }
          }
        }
      }
      .additionalArea {
        position: relative;
        margin-top: 24px;
        span:after {
          //display:block;
          //content:'';
          //border-width:8px 8px 8px 8px;
          //border-style:solid;
          //border-color:#fff #ff4d4d #ff4d4d #ff4d4d;
          //
          ///* 定位 */
          //position:absolute;
          //left:40%;
          //top:100%;
        }
        p {
          font-size: 14px;
          color: rgba(118, 118, 118, 1);
          line-height: 15px;
        }
        ul {
          margin-left: 36px;
          text-align: center;
          li {
            display: inline-block;
            margin-right: 54px;
            font-size: 12px;
            color: #bdc3c7;
            i {
              font-style: normal;
              color: #fe4365;
            }
            a {
              color: #bdc3c7;
            }
          }
        }
        /*p{
          position: absolute;
          right: 0;
          top: 34px;

          font-size: 10px;
          color: #bdc3c7;
          letter-spacing: 0;
          line-height: 20px;
          transform: scale(0.9);
          a{
            color: #bdc3c7;
          }
        }*/
      }
      //付款码
      .payArea {
        margin: 40px auto 20px;
        position: relative;
        text-align: left;
        margin-top: 0px;
        height: 222px;
        margin-bottom: 5px;
        background: #fff;
        padding: 0px 79px 0 78px;
        .payContent {
          margin: 0 auto;
          position: relative;
          //width: 446px;
          padding: 30px 0;
          height: 100%;
          .alipayqr,
          .wexinqr {
            position: relative;
            width: 120px;
            height: 120px;
            .refreshPay {
              position: absolute;
              z-index: 10;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              opacity: 0;
              background-color: rgba(0, 0, 0, 0.6);
              transition: all 0.3s ease-in;
              cursor: pointer;
              color: #fff;
              line-height: 120px;
              text-align: center;
            }
            .qrcode {
              background: #fff;
              width: 120px;
              height: 120px;
              position: static;
              padding: 0;
              top: 0;
              left: 0;
              border: none;
              .payqrcode,
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .alipayqr:hover,
          .wexinqr:hover {
            .refreshPay {
              opacity: 1;
            }
          }
          .paytype {
            font-size: 16px;
            font-weight: 400;
            color: rgba(118, 118, 118, 1);
            margin-top: 16px;
            /*width: 150px;
            margin-left: -20px;*/
            text-align: center;
            .payicon {
              vertical-align: middle;
              display: inline-block;
              width: 26px;
              height: 26px;
              margin-right: 5px;
              margin-top: -3px;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center center;
            }
            .payicon.alipay {
              background-image: url("../../../assets/images/alipay.png");
            }
            .payicon.wxpay {
              background-image: url("../../../assets/images/wechat.png");
            }
          }
          h3 {
            width: 226px;
            float: left;
            font-size: 12px;
            margin: 15px 0 6px 0;
            text-align: center;
            color: #7f8c8d;
            margin-left: 18px;
            > span {
              color: $color-black;
            }
            .should_title{
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              vertical-align: top;
              position: relative;
              top: 12px;
              left: 29px;
              display: inline-block;
            }
            .should {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              font-weight: 400;
              vertical-align: top;
              margin-top: 15px;
              position: relative;
              top: 16px;
              left: 27px;
            }
            .maxPrice{
              display: inline-block;
              color: #767676;
              font-size: 20px;
              position: relative;
              left: 28px;
              top: -2px;
              span{
                font-size: 12px;
                position: relative;
                top: -8px;
                left: 1px;
              }
              &::after{
                content: "";
                width: 44px;
                height: 1px;
                background: #767676;
                position: absolute;
                display: block;
                -webkit-transform: rotate(26deg);
                transform: rotate(26deg);
                top: 13px;
                left: 3px;
              }
            }
            .service {
              display: inline-block;
              width: auto;
              background: none;
              height: auto;
              color: rgba(120, 120, 120, 1);
              padding: 0px;
              margin: 0px;
            }
            .payPrice {
              font-size: 40px;
              display: inline-block;
              line-height: 40px;
              color: #FF4D4D;
              position: relative;
              left: 26px;
              top: -1px;
            }
            > p {
              line-height: 14px;
              font-weight: 400;
              color: rgba(102, 102, 102, 1);
              vertical-align: middle;
              text-align: center;
              font-size: 14px;
              margin-top: 8px;
              white-space: nowrap;
              > span {
                display: inline-block;
                width: 14px;
                height: 13px;
                background: url("#{$cdn_xcx_path}/p/newVip/vip_redgou.svg?1") no-repeat 50%;
                margin-right: 5px;
              }
              a {
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url("#{$cdn_xcx_path}/p/newVip/newVip_icon_1.svg") no-repeat 50%;
                margin-left: 5px;
                position: relative;
                top: 3px;
                left: -2px;
              }
            }
            .infor_text1{
              cursor: pointer;
              margin-top: 27px;
              position: relative;
              left: 25px;
              top: 0px;
            }
            .infor_text2{
              position: relative;
              left: 32px;
              top: 4px;
            }
            .infor_text3{
              position: relative;
              left: 31px;
              top: 10px;
            }
          }
        }
        .right-tips {
          position: absolute;
          right: 6px;
          bottom: 4px;
          font-weight: 400;
          color: #AAAAAA;
          font-size: 10px;
          a {
            color: #AAAAAA;
            &:hover {
              color: #fe4365;
            }
          }
        }
      }
      .more {
        display: block;
        padding: 0px 32px;
        .hot-power {
          padding: 0 4px;
          .titie {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 24px 0 20px;
            .left-t {
              font-size: 20px;
              font-weight: 400;
              color: #333333;
            }
            .right-t {
              font-size: 16px;
              font-weight: 400;
              color: #767676;
              span {
                background: url("../../../assets/images/kefu.png");
                height: 17px;
                width: 20px;
                background-size: cover;
                display: inline-block;
                margin-right: 8px;
                vertical-align: middle;
              }
            }
          }
          .powerList {
            display: -webkit-flex;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .item {
              width: 142px;
              height: 154px;
              background: rgba(255, 255, 255, 1);
              box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.15);
              //justify-content: center;
              align-items: center;
              display: flex;
              flex-direction: column;
              margin: 13px 16px;
              padding-top: 18px;
              position: relative;
              .top-l {
                position: relative;
                align-items: center;
                justify-content: center;
                display: -webkit-flex;
                display: flex;
                width: 64px;
                height: 64px;
                //border: 1px solid rgba(255, 77, 77, 1);
                //border-radius: 100%;
                margin-bottom: 11px;
                background-size: cover;
                .ai{
                  position: absolute;
                  bottom: 0px;
                  right: 4px;
                  width: 20px;
                  height: 20px;
                  border-radius: 8px;
                  font-weight: 800;
                  background: #ffffff;
                  font-size: 18px;
                  color: #FF0000;
                  line-height: 18px;
                  text-align: right;
                  font-style: normal;
                  letter-spacing: -1px;
                }
              }
              .small-text{
                position: absolute;
                width: 64px;
                height: 20px;
                line-height: 20px;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px 0px rgba(255, 77, 77, 0.2);
                border-radius: 4px;
                font-size: 12px;
                font-weight: 500;
                color: #FF4D4D;
                bottom:64px;
              }
              .big-t {
                font-size: 18px;
                //font-weight: bold;
                color: #333333;
                line-height: 25px;
                font-weight: 500;
                .on_fun{
                  font-size: 12px;
                  position: absolute;
                  font-weight: 400;
                  color: #767676;
                  bottom: 35px;
                  white-space: nowrap;
                }
              }
              .small-t {
                font-size: 12px;
                font-weight: 400;
                color: #767676;
                line-height: 22px;
              }
              .small-ts {
                line-height: 12px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(118, 118, 118, 1);
              }
            }
          }
        }
        .else-power {
          .title {
            margin-top: 15px;
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            text-align: left;
            padding-left: 22px;
          }
          .list {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            flex-wrap: wrap;
            padding-right: 10px;
            .item {
              width: 25%;
              display: flex;
              //justify-content: center;
              align-items: center;
              margin-bottom: 12px;
              margin-top: 17px;
              padding-left: 23px;
              .left {
                width: 34px;
                height: 34px;
                //border: 1px solid #ff4d4d;
                //border-radius: 100%;
                margin-right: 8px;
                display: -webkit-flex;
                justify-content: center;
                align-items: center;
                .power1 {
                  width: 34px;
                  height: 34px;
                  background-size: 100%;
                  display: inline-block;
                }
              }
              .right {
                .box-center {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: left;
                  flex-direction: column;
                  .top {
                    font-size: 18px;
                    color: #333333;
                    line-height: 18px;
                    //font-weight: 600;
                    text-align: left;
                    width: 117px;
                  }
                  .bottom {
                    font-size: 12px;
                    font-weight: 400;
                    color: #767676;
                    line-height: 20px;
                    white-space: nowrap;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
    //选择框
    .VIPSelectBox {
      margin-top: 0;
      width: 100%;
      h3 {
        text-align: center;
        line-height: 20px;
        font-size: 20px;
        font-weight: 500;
        color: rgba(57, 57, 57, 1);
      }
      > p {
        padding: 16px 0px;
        font-size: 20px;
        font-weight: 500;
        color: #FF4D4D;
        line-height: 20px;
        min-height: 52px;
      }
      .num-designer {
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #767676;
      }
      .VIPSelect {
        margin: 22px auto;
        min-height:216px;
        text-align: center;
        //padding-top: 6px;
        overflow: inherit;
        .item {
          cursor: pointer;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          display: inline-block;
          text-align: center;
          height: auto;
          width: 232px;
          margin: 0 7px;
          position: relative;
          .reduction {
            display: none;
            position: absolute;
            color: transparent;
            top: 10px;
            background-image: url("#{$cdn_xcx_path}/p/newVip/fuli_new_t.svg");
            left: -14px;
            width: 73px;
            height: 74px;
            background-size: cover;
          }
          .countdown{
            width:133px;
            height: 24px;
            border-radius: 4px;
            background-color: #FF4D4D;
            position: absolute;
            right:-2px;
            top:-12px;
            z-index: 100;
            letter-spacing: 1px;
            .time{
              font-size:16px;
              color:rgba(255,255,255,1);
              line-height:24px;
              text-align: center;
            }
          }
          > span {
            width: 120px;
            position: relative;
          }
          .viptime {
            font-size: 22px;
            font-weight: 500;
            line-height: 22px;
            color: #8D8D8D;
            display: block;
            margin: 24px auto 12px auto;
            .addTime {
              font-size: 14px;
              font-weight: 400;
              color: rgba(118, 118, 118, 1);
              vertical-align: bottom;
              line-height: 18px;
              position: absolute;
              white-space: nowrap;
              top: 5px;
              padding-left: 2px;
            }
          }
          .active_p {
            margin-bottom: 13px !important;
          }
          .yuan {
            font-size: 14px;
            color: $color-gray2;
            font-weight: 400;
            left: auto;
            top: auto;
            display: block;
            background: none;
            width: 80px;
            margin: 0 auto;
            .fuli {
              position: absolute;
              text-align: left;
              top: 50%;
              margin-top: -15px;
              line-height: 15px;
              font-size: 14px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              height: 30px;
              right: -69px;
            }
            .dafuli {
              width: 60px;
              text-align: left;
              align-items: flex-start;
              justify-content: flex-start;
              white-space: nowrap;
              right:0;left:0;
              transform: translate(80px,0);
            }
            > span {
              font-size: 22px;
              font-weight: 500;
              color: #8D8D8D;
              line-height: 40px;
              position: relative;
              top: 12px;
            }
            .rmb {
              position: absolute;
              font-size: 22px;
              top: 0px;
              line-height: 22px;
              left: -18px;
              color: #8D8D8D;
            }
            .priceVal {
              font-size: 40px;
              width: 80px;
              display: inline-block;
              white-space: nowrap;
              word-break:break-word;
            }
          }
          .original_p_t {
            line-height: 22px;
            margin-bottom: 8px !important;
            color: #AAAAAA;
            > span {
              font-size: 22px;
            }
            .rmb-1 {
              display: inline-block;
              vertical-align: super;
              top: 12px;
              position: relative;
              left: -4px;
            }
            .oPriceVal {
              line-height: 22px;
              color: #AAAAAA;
              top: 12px;
              left: -7px;
            }
            .oPriceValone {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              line-height: 40px;
              position: absolute;
              display: inline-block;
              width: 27px;
              right: -24px;
              top: -7px;
            }
            .fuli {
              right: -14px;
              font-size: 12px;
              top: 27px;
            }
          }
          .original_p_t:after {
            content: "";
            display: block;
            position: absolute;
            width: 49px;
            height: 1px;
            background: #AAAAAA;
            left: 60%;
            top: 27px;
            margin-left: -35px;
            margin-top: -1px;
          }
          .original_p_t_four:after {
            content: "";
            display: block;
            position: absolute;
            width: 62px;
            height: 1px;
            background: #AAAAAA;
            left: 60%;
            top: 27px;
            margin-left: -42px;
            margin-top: -1px;
          }
          .original_p_b {
            line-height: 22px;
            margin-bottom: 8px !important;
            color: #AAAAAA;
            > span {
              font-size: 22px;
            }
            .rmb-1 {
              display: inline-block;
              vertical-align: super;
              position: relative;
              top: 5px;
              left: -5px;
            }
            .oPriceVal {
              line-height: 22px;
              color: #AAAAAA;
              position: relative;
              top: 5px;
              left: -8px;
            }
            .oPriceValone {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              line-height: 40px;
              position: absolute;
              display: inline-block;
              width: 27px;
              right: -10px;
              top: -1px;
            }
            .fuli {
              right: -32px;
            }
          }
          .original_p_b:after {
            content: "";
            display: block;
            position: absolute;
            width: 49px;
            height: 1px;
            background: #AAAAAA;
            left: 59%;
            top: 20px;
            margin-left: -35px;
            margin-top: -1px;
          }
          .original_p_b_four:after{
            content: "";
            display: block;
            position: absolute;
            width: 62px;
            height: 1px;
            background: #AAAAAA;
            left: 59%;
            top: 20px;
            margin-left: -42px;
            margin-top: -1px;
          }

          .perdayPriceCont {
            font-weight: 400;
            color: #AAAAAA;
            font-size: 12px;
            width: 120px;
            position: relative;
            display: block;
            margin: 10px auto;
            > i {
              font-style: normal;
              color: #AAAAAA;
            }
          }
          .VIPdec {
            font-size: 12px;
            color: #bdc3c7;
            line-height: 22px;
            top: auto !important;
          }
        }
        .item.active {
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          border: 2px solid rgba(255, 77, 77, 1);
          .yuan {
            color: $color-red;
            .rmb {
              position: absolute;
              font-size: 22px;
              top: 0px;
              line-height: 22px;
              left: -18px;
              color: #333333;
            }
          }
          .viptime {
            color: #333333;
            .addTime {
              color: rgba(255, 77, 77, 1);
            }
            .invitedTips{
              color: #FF4D4D;
            }
          }
          .fuli {
            color: #ff4d4d;
          }
          .oPriceValone{
            color: #767676;
          }
          .original_p_t{
            color: #767676;
            .oPriceVal {
              line-height: 22px;
              color: #767676;
            }
          }
          .original_p_t:after {
            //background: $color-red;
            content: "";
            display: block;
            position: absolute;
            width: 49px;
            height: 1px;
            background: #767676;
            left: 60%;
            top: 27px;
            margin-left: -35px;
            margin-top: -1px;
          }
          .original_p_t_four:after{
            content: "";
            display: block;
            position: absolute;
            width: 62px;
            height: 1px;
            background: #767676;
            left: 60%;
            top: 27px;
            margin-left: -42px;
            margin-top: -1px;
          }

          .original_p_b{
            color: #767676;
            .oPriceVal {
              line-height: 22px;
              color: #767676;
            }
          }
          .original_p_b:after {
            //background: $color-red;
            content: "";
            display: block;
            position: absolute;
            width: 49px;
            height: 1px;
            background: #767676;
            left: 59%;
            top: 20px;
            margin-left: -35px;
            margin-top: -1px;
          }
          .original_p_b_four:after{
            content: "";
            display: block;
            position: absolute;
            width: 62px;
            height: 1px;
            background: #767676;
            left: 59%;
            top: 20px;
            margin-left: -42px;
            margin-top: -1px;
          }
          .priceVal {
            color: #333333;
          }
          .perdayPriceCont{
            color: #767676;
            i{
              color: #767676;
            }
          }
        }
        /*.item.active:after {
          width: 26px;
          height: 25px;
          position: absolute;
          right: -1px;
          bottom: 0;
          display: block;
          content: "";
          background-image: url("../../../assets/images/selecticon_.png");
        }*/
        .item.isReduction {
          .reduction {
            display: block;
            top: -11px;
          }
        }
      }
    }
    a.red,
    span.red {
      color: $color-red;
    }
  .examine {
    margin-top: 14px;
    cursor: pointer;
    color: #333333;
    //margin-bottom: 33px;
  }
  .VIPDialog.dialogBox {
    .CenterPromptbox {
      overflow: visible;
      width: 810px;
      .closedthismask:hover {
        //right: -20px;
        background: url("#{$cdn_wimg_path}/p/images/Icon15.png") no-repeat -30px -60px;
      }
      //.closedthismask:after {
      //  content: '我知道了';
      //  display: block;
      //  color: #fff;
      //  line-height: 20px;
      //  height: 20px;
      //  cursor: pointer;
      //  position: absolute;
      //  top: 50%;
      //  margin-top: -10px;
      //  left: 20px;
      //  width: 60px;
      //}
    }
  }

  .el-collapse-item__header {
    display: inline !important;
    font-size: 14px !important;
    color: #767676;
    border-bottom: none;
  }

  .el-collapse {
    border: none !important;
  }

  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .bottom_button {
    width: 178px;
    height: 48px;
    background: #FF4D4D;
    border-radius: 4px;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 26px;
    div{
      width: 178px;
      height: 48px;
      border-radius: 4px;
      text-align: center;
      line-height: 48px;
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      background: rgba(0,0,0,0);
    }
    &:hover {
      div{
        background: rgba(0,0,0,0.1);
      }
    }
  }
  .el-collapse-item__content:nth-child(2) {
    display: none;
  }
  .el-collapse-item__arrow.is-active {
    display: none;
  }
  .el-collapse-item__wrap {
    .el-collapse-item__content {
      padding-bottom: 0px !important;
    }
  }
  .el-collapse-item__arrow {
    display: none;
  }
  .drop_icon {
    background: url("#{$cdn_xcx_path}/p/newVip/vip_new_cila.png") no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    height: 14px;
    width: 14px;
    margin-left: 6px;
    margin-top: -2px;
  }

}
