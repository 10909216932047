@charset "UTF-8";
.font-regular {
  font-family: "SourceHanSansSC-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-medium {
  font-family: "SourceHanSansSC-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.font-bold, .dropdownToolsBox .tools .version h4 {
  font-family: "SourceHanSansSC-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue,Helvetica,Arial,"PingFang SC", "Hiragino Sans GB","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight: normal;
  font-style: normal; }

.dropdownToolsBox {
  margin-left: -77px; }
  .dropdownToolsBox:global(.dropdownWrapper) {
    width: 154px; }
    .dropdownToolsBox:global(.dropdownWrapper) :global(.dropdownMain) {
      padding: 0;
      margin-top: 8px;
      border-radius: 4px;
      border: 1px solid #E5E5E5; }
    .dropdownToolsBox:global(.dropdownWrapper)::before {
      content: " ";
      border: 8px solid transparent;
      border-bottom: 8px solid #fff;
      width: 0;
      height: 0;
      position: absolute;
      top: -7px;
      left: 54%;
      margin-left: -14px;
      filter: drop-shadow(0px -1px 0px rgba(0, 0, 0, 0.1)); }
  .dropdownToolsBox .tools {
    margin: 0 auto;
    padding-bottom: 10px; }
    .dropdownToolsBox .tools > a::after {
      position: absolute;
      bottom: -1px;
      background: linear-gradient(270deg, rgba(229, 229, 229, 0) 0%, #E5E5E5 52%, rgba(229, 229, 229, 0) 100%);
      content: '';
      width: 122px;
      height: 1px;
      left: 15px;
      top: -1px; }
    .dropdownToolsBox .tools .version {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      padding: 0 16px;
      cursor: default; }
      .dropdownToolsBox .tools .version h4 {
        font-size: 14px;
        transform-origin: 0 0;
        transform: translate(0, 7px); }
      .dropdownToolsBox .tools .version span {
        font-size: 12px;
        color: #aaaaaa;
        transform: scale(0.86) translate(0, 8px);
        transform-origin: 100% 100%; }
    .dropdownToolsBox .tools .toolsItem {
      position: relative;
      height: 38px;
      cursor: pointer;
      color: #333333;
      font-size: 14px;
      display: flex;
      box-sizing: content-box;
      justify-content: center;
      align-items: center;
      padding-bottom: 1px; }
      .dropdownToolsBox .tools .toolsItem:hover {
        background-color: #F0F0F0; }
        .dropdownToolsBox .tools .toolsItem:hover .toolsName {
          color: #333333; }
        .dropdownToolsBox .tools .toolsItem:hover .img {
          display: none; }
        .dropdownToolsBox .tools .toolsItem:hover .hoverimg {
          display: inline-block; }
      .dropdownToolsBox .tools .toolsItem.hot::before {
        display: block;
        content: " ";
        width: 24px;
        height: 12px;
        background: url("../../../assets/images/icon_hot.svg") no-repeat center center;
        position: absolute;
        left: auto;
        right: 11px;
        top: 6px;
        z-index: 1;
        background-image: url("../../../assets/images/icon_hot.svg"); }
      .dropdownToolsBox .tools .toolsItem.new::before {
        display: block;
        content: " ";
        width: 24px;
        height: 12px;
        background: url("../../../assets/images/icon_hot.svg") no-repeat center center;
        position: absolute;
        left: auto;
        right: 11px;
        top: 6px;
        z-index: 1;
        background-image: url("../../../assets/images/icon_new.svg"); }
      .dropdownToolsBox .tools .toolsItem.more .toolsName {
        transform: translate(6px, 0); }
      .dropdownToolsBox .tools .toolsItem .toolsName {
        position: relative;
        color: #333333;
        font-size: 14px;
        text-align: left;
        text-indent: -1px; }
        .dropdownToolsBox .tools .toolsItem .toolsName .ai {
          margin-right: 4px; }
    .dropdownToolsBox .tools > div:nth-child(-n+2) {
      margin-bottom: 4px; }
