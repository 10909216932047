@charset "UTF-8";
.dropdownToolsBox {
  margin-left: -77px; }
  .dropdownToolsBox :global(.dropdownMain) {
    padding: 0;
    margin-top: 8px;
    overflow: inherit; }
    .dropdownToolsBox :global(.dropdownMain) :global(.dropdownOuter) :global(.dropdownWrapper) {
      display: none;
      width: 160px;
      left: -82px;
      top: -10px;
      padding-right: 6px; }
      .dropdownToolsBox :global(.dropdownMain) :global(.dropdownOuter) :global(.dropdownWrapper)::before {
        display: none; }
    .dropdownToolsBox :global(.dropdownMain) :global(.dropdownOuter):hover :global(.dropdownWrapper) {
      display: block; }
  .dropdownToolsBox .tools {
    margin: 0 auto; }
    .dropdownToolsBox .tools li:first-child::after {
      display: none; }
    .dropdownToolsBox .tools > li::after {
      position: absolute;
      bottom: -1px;
      background-color: #F0F0F0;
      content: '';
      width: 122px;
      height: 1px;
      left: 15px;
      top: -2px; }
    .dropdownToolsBox .tools > li:nth-child(6) .toolsName {
      line-height: 43px; }
    .dropdownToolsBox .tools > li:nth-child(7) .toolsName {
      line-height: 43px;
      letter-spacing: 1px; }
    .dropdownToolsBox .tools > li:last-child {
      height: 44px; }
      .dropdownToolsBox .tools > li:last-child .icon {
        top: 51%; }
    .dropdownToolsBox .tools .new::before {
      display: block;
      content: " ";
      width: 24px;
      height: 12px;
      background: url("../../../assets/images/icon_hot.svg") no-repeat center center;
      position: absolute;
      left: auto;
      right: 12px;
      top: 4px;
      z-index: 1;
      background-image: url("../../../assets/images/icon_new.svg"); }
    .dropdownToolsBox .tools .toolsItem {
      position: relative;
      height: 47px;
      text-align: center;
      cursor: pointer;
      color: #333333;
      padding-left: 46px;
      font-size: 14px;
      display: block;
      box-sizing: content-box; }
      .dropdownToolsBox .tools .toolsItem .img {
        display: inline-block; }
      .dropdownToolsBox .tools .toolsItem .hoverimg {
        display: none; }
      .dropdownToolsBox .tools .toolsItem:hover {
        background-color: #F0F0F0; }
        .dropdownToolsBox .tools .toolsItem:hover::after {
          display: none; }
        .dropdownToolsBox .tools .toolsItem:hover .toolsName {
          color: #333333; }
        .dropdownToolsBox .tools .toolsItem:hover .img {
          display: none; }
        .dropdownToolsBox .tools .toolsItem:hover .hoverimg {
          display: inline-block; }
      .dropdownToolsBox .tools .toolsItem .icon {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 21px;
        top: 47%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center; }
      .dropdownToolsBox .tools .toolsItem .toolsName {
        position: relative;
        height: 47px;
        line-height: 44px;
        color: #333333;
        font-size: 14px;
        text-align: left;
        text-indent: -1px; }
    .dropdownToolsBox .tools > div:nth-child(-n+2) {
      margin-bottom: 4px; }

.fl {
  float: left; }

.fr {
  float: right; }

/** 图标 */
.toolsItem .icon {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center center; }
  .toolsItem .icon.fanli {
    background-image: url("../../../assets/images/icon_header_fanli.svg"); }
  .toolsItem .icon.jpg-yasuotu {
    background-image: url("https://xcx-img.bigbigwork.cn/p/search/header_tools_icon_jpgyasuotu.svg?2"); }
  .toolsItem .icon.png-yasuotu {
    background-image: url("https://xcx-img.bigbigwork.cn/p/search/header_tools_icon_pngyasuotu.svg?2"); }
  .toolsItem .icon.caijiqi {
    background-image: url("../../../assets/images/caijiqi.svg?2");
    background-size: 14px 14px; }
  .toolsItem .icon.khd {
    background-image: url("../../../assets/images/icon_header_client.svg"); }
  .toolsItem .icon.zdt {
    background-image: url("../../../assets/images/icon_header_zoomin.svg");
    width: 22px !important; }
  .toolsItem .icon.ai {
    background-image: url("../../../assets/images/icon_header_ai.svg"); }
  .toolsItem .icon.yasuotu {
    background-image: url("../../../assets/images/icon_header_yasuotu.svg"); }
  .toolsItem .icon.qqtj {
    background-image: url("https://xcx-img.bigbigwork.cn/p/search/header_tools_icon_new_5.svg?2"); }
  .toolsItem .icon.fx {
    background-image: url("../../../assets/images/icon_header_faxian.svg"); }
  .toolsItem .icon.goDesign {
    background-image: url("../../../assets/images/icon_header_godesign.svg"); }
  .toolsItem .icon.bj {
    background-image: url("../../../assets/images/icon_header_baojiang.svg"); }
