@charset "UTF-8";
.findDesign::after {
  content: '/';
  position: absolute;
  right: 0;
  top: 1px; }

.findDesign > div > span {
  padding-right: 7px !important; }

.findProject > div > span {
  padding-left: 2px !important; }

.fanli .fanliTip {
  position: absolute;
  left: 50%;
  margin-left: -70px;
  top: 27px;
  z-index: 999;
  background-image: url("../../../assets/images/fanliTip.svg");
  background-repeat: no-repeat;
  width: 139px;
  height: 52px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN, "Microsoft Yahei", 微软雅黑, sans-serif;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 15px 10px 25px;
  line-height: 18px; }

.aiTool :global(.navItemBk:hover .navItem_des) {
  color: #6BCC38; }

.qiwei_icon {
  width: 124px;
  height: 24px;
  background-image: url("https://dz-img.bigbigwork.cn/3w/qw_1.svg");
  margin-right: 30px;
  cursor: pointer;
  position: relative; }

.ai_tool {
  line-height: 24px; }
  .ai_tool .ai {
    position: relative;
    display: flex;
    align-items: center;
    color: #767676;
    cursor: pointer; }
  .ai_tool .text {
    font-size: 16px;
    line-height: 16px;
    position: relative; }
  .ai_tool .text-hover {
    display: none; }
  .ai_tool:hover .text {
    display: none; }
  .ai_tool:hover .text-hover {
    display: block; }

@media (max-width: 1439px) {
  .qiwei_icon {
    width: 32px;
    height: 24px;
    margin-right: 16px;
    background-image: url("https://dz-img.bigbigwork.cn/3w/qw_2.svg"); } }
